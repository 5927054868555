import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './App.scss';
import NavigationBar from './NavigationBar';
import Footer from './Footer';
import BronzeDocsPage from './BronzeDocsPage';
import BronzeDocsExpiredPage from './BronzeDocsExpiredPage';
import InvalidRoutePage from './InvalidRoutePage';

function App() {
  return (
    <div className="sh-wrapper">
      <div>
        <NavigationBar />

        <article className="content my-2">
          <Switch>
            <Route exact path="/bronze/:orderReference" component={BronzeDocsPage} />
            <Route exact path="/bronze/expired/:orderReference" component={BronzeDocsExpiredPage} />
            <Route path="*" component={InvalidRoutePage} />
          </Switch>
        </article>
      </div>

      <div className="m-0 p-0">
        <div className="bg-primary text-white mt-2 py-2">
          <footer className="container">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
}

export default App;
