import React from 'react';
import PropTypes from 'prop-types';

function Component(props) {
  const { href, children, className } = props;
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={className}>{children}</a>
  );
}

Component.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Component.defaultProps = {
  className: null,
};

export default Component;
