/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import bootstrapIcons from 'bootstrap-icons/bootstrap-icons.svg';
import Button from './Button';
import ErrorAlert from './ErrorAlert';
import Links from './BronzeDocsLinks';

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailAddress: '',
      loginIsFetching: false,
      loginFailureMessage: null,
      sessionId: null,
      files: null, // array
      filesFetching: [], // of String (file.name)
      downloadFailureMessages: {}, // where key is String (file.name)
      filesDownloaded: [], // of String (file.name)
    };

    this.onLoginFormInputChange = this.onLoginFormInputChange.bind(this);
    this.onLoginFormSubmit = this.onLoginFormSubmit.bind(this);
    this.onDownloadButtonClick = this.onDownloadButtonClick.bind(this);
  }

  onLoginFormInputChange(event) {
    this.setState({
      emailAddress: event.target.value,
    });
  }

  async onLoginFormSubmit(event) {
    event.preventDefault();
    const { emailAddress } = this.state;
    const { match } = this.props; // from Route
    this.setState({
      loginIsFetching: true,
    });

    const requestMessage = {
      checkoutId: match.params.orderReference,
      email: emailAddress,
    };
    const request = new Request('https://europe-west2-silk-helix.cloudfunctions.net/docs-login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestMessage),
    });

    let response;
    try {
      response = await fetch(request);
    } catch (error) {
      this.setState({
        loginIsFetching: false,
        loginFailureMessage: error.toString(),
      });
      return;
    }

    if (response.status === 200) {
      const responseMessage = (await response.json()).data;
      this.setState({
        loginIsFetching: false,
        sessionId: responseMessage.sessionId,
        files: sortProductFileMap(responseMessage.product.files),
      });
      return;
    }

    // Handle errors
    const errorMessage = await errorMessageFromFetchResponse(response);

    switch (response.status) {
      case 400: // Generic Error (e.g. invalid request)
        break;

      case 401: // Unauthorised
        // Order id not found, or the email address was wrong.
        break;

      case 403: // Forbidden
        // We're probably awaiting our success callback from Stripe.
        // In which case, all we can suggest is that they try again.
        break;

      default:
        // Unknown reason for failure.
    }

    this.setState({
      loginIsFetching: false,
      loginFailureMessage: errorMessage,
    });
  }

  async onDownloadButtonClick(fileName) {
    const { match } = this.props; // from Route
    const { sessionId } = this.state;

    this.setState((prevState) => ({
      // Add this fileName to filesFetching
      filesFetching: [
        fileName,
        ...prevState.filesFetching,
      ],

      // Remove this fileName from downloadFailureMessages, if it's there at the moment
      downloadFailureMessages: cloneObjectWithKeyRemovedIfItExists(prevState.downloadFailureMessages, fileName),
    }));

    const requestMessage = {
      checkoutId: match.params.orderReference,
      sessionId,
      fileName,
    };
    const request = new Request('https://europe-west2-silk-helix.cloudfunctions.net/docs-requestDownload', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestMessage),
    });

    let response;
    try {
      response = await fetch(request);
    } catch (error) {
      this.setState((prevState) => ({
        // Remove this fileName from filesFetching
        filesFetching: prevState.filesFetching.filter((element) => element !== fileName),

        // Add this fileName to downloadFailureMessages
        downloadFailureMessages: {
          ...prevState.downloadFailureMessages,
          fileName: error.toString(),
        },
      }));
      return;
    }

    if (response.status === 200) {
      const { token } = (await response.json()).data;
      const downloadUrl = `https://europe-west2-silk-helix.cloudfunctions.net/docs-download?token=${token}`;
      const newWindow = window.open(downloadUrl, '_self', 'noopener');
      if (newWindow) {
        newWindow.opener = null;
      }

      this.setState((prevState) => ({
        // Remove this fileName from filesFetching
        filesFetching: prevState.filesFetching.filter((element) => element !== fileName),

        // Add this fileName to filesDownloaded
        filesDownloaded: [
          fileName,
          ...prevState.filesDownloaded,
        ],
      }));
      return;
    }

    // Handle errors
    const errorMessage = await errorMessageFromFetchResponse(response);
    this.setState((prevState) => ({
      // Remove this fileName from filesFetching
      filesFetching: prevState.filesFetching.filter((element) => element !== fileName),

      // Add this fileName to downloadFailureMessages
      downloadFailureMessages: {
        ...prevState.downloadFailureMessages,
        fileName: errorMessage,
      },
    }));
  }

  render() {
    const { sessionId, loginFailureMessage } = this.state;

    if (sessionId === null) {
      const { emailAddress, loginIsFetching } = this.state;

      // using the technique called "Controlled Components":
      //   https://reactjs.org/docs/forms.html#controlled-components
      return (
        <div className="container">
          <LoginForm
            key="loginForm"
            onFormSubmit={this.onLoginFormSubmit}
            onInputChange={this.onLoginFormInputChange}
            emailAddress={emailAddress}
            isFetching={loginIsFetching}
          />
          {loginFailureMessage && <ErrorAlert message={loginFailureMessage} />}
        </div>
      );
    }

    // We're logged in (a.k.a. found the order).
    const {
      files,
      filesFetching,
      downloadFailureMessages,
      filesDownloaded,
    } = this.state;
    return (
      <div className="container">
        <FileList
          files={files}
          filesFetching={filesFetching}
          downloadFailureMessages={downloadFailureMessages}
          filesDownloaded={filesDownloaded}
          onDownloadClick={this.onDownloadButtonClick}
        />
        <Links />
      </div>
    );
  }
}

Component.propTypes = {
  // https://stackoverflow.com/a/47311600/392847
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderReference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Component;

function LoginForm(props) {
  const {
    onFormSubmit,
    onInputChange,
    emailAddress,
    isFetching,
  } = props;

  return (
    <form onSubmit={onFormSubmit}>
      <div className="my-3">
        <label htmlFor="emailAddress" className="form-label">
          To access your documents, please enter the email address you supplied on checkout:
        </label>
        <input
          type="email"
          value={emailAddress}
          onChange={onInputChange}
          className="form-control"
          id="emailAddress"
          disabled={isFetching}
        />
      </div>
      <Button
        label="Authenticate"
        workingLabel="Authenticating..."
        isWorking={isFetching}
      />
    </form>
  );
}

// https://reactjs.org/docs/typechecking-with-proptypes.html#proptypes
LoginForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  emailAddress: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

function FileList(props) {
  const {
    files,
    filesFetching,
    downloadFailureMessages,
    filesDownloaded,
    onDownloadClick,
  } = props;

  const rowElements = files.map((file) => (
    <tr key={file.name}>
      <td>
        <div className="d-flex">
          <div>
            <svg className="bi me-2" width="1.5em" height="1.5em" fill="currentColor">
              <use xlinkHref={`${bootstrapIcons}#${bootstrapIconForFileExtension(file.extension)}`} />
            </svg>
          </div>
          <div className="d-flex flex-column">
            <div className="font-monospace">
              {file.name}.{file.extension}
            </div>
            {file.description && <div dangerouslySetInnerHTML={wrapDangerousHtml(file.description)} />}
          </div>
        </div>
        {file.name in downloadFailureMessages && <ErrorAlert message={downloadFailureMessages[file.name]} />}
      </td>
      <td>
        <Button
          label="Download File"
          icon="download"
          workingLabel="Downloading..."
          isWorking={filesFetching.includes(file.name)}
          isDisabled={filesDownloaded.includes(file.name)}
          onClick={() => onDownloadClick(file.name)}
        />
      </td>
    </tr>
  ));

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">File</th>
          <th scope="col">Download</th>
        </tr>
      </thead>
      <tbody>
        {rowElements}
      </tbody>
    </table>
  );
}

FileList.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    extension: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
  })).isRequired,
  filesFetching: PropTypes.arrayOf(PropTypes.string).isRequired,
  downloadFailureMessages: PropTypes.objectOf(PropTypes.string).isRequired,
  filesDownloaded: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDownloadClick: PropTypes.func.isRequired,
};

function sortProductFileMap(fileMap) {
  // flatten map into an array
  const files = [];
  Object.keys(fileMap).forEach((name) => {
    files.push({
      name,
      ...fileMap[name],
    });
  });

  // sort the array
  files.sort((file1, file2) => {
    if (file1.order < file2.order) {
      return -1;
    }
    if (file1.order > file2.order) {
      return 1;
    }
    return 0;
  });

  return files;
}

function bootstrapIconForFileExtension(extension) {
  switch (extension) {
    case 'pdf':
      return 'file-earmark-pdf';

    case 'docx':
      return 'file-earmark-word';

    default:
      return 'file-earmark-text';
  }
}

function wrapDangerousHtml(html) {
  // per: https://reactjs.org/docs/dom-elements.html#dangerouslysetinnerhtml
  // TODO consider using something like https://github.com/cure53/DOMPurify on the off-chance
  // we don't trust the HTML being injected.
  return {
    __html: html,
  };
}

function cloneObjectWithKeyRemovedIfItExists(object, key) {
  const clone = { ...object };
  delete clone[key];
  return clone;
}

/**
 * Conforms a message appropriate given the way our back-end works (Firebase Functions).
 * If the status code is 4xx then we know it came explicitly from our back-end code, otherwise
 * it's something unexpected.
 *
 * @param {*} response The object received back from a call to fetch().
 * @returns {String} An error message that we can display to the user.
 */
async function errorMessageFromFetchResponse(response) {
  return (response.status >= 400 && response.status <= 499)
    ? `${(await response.json()).message} Status code ${response.status}.`
    : `Our server returned status code ${response.status}. This was unexpected.`;
}
