import React from 'react';
import PropTypes from 'prop-types';
import ErrorAlert from './ErrorAlert';

function Component(props) {
  const { match } = props; // from Route
  const { orderReference } = match.params;

  return (
    <div className="container">
      <ErrorAlert message="You tried to access an expired link." />
      <p>Please <a href={`/bronze/${orderReference}`}>try again</a>.</p>
      <p>This should not normally happen. If it keeps on happening then it may be something we are doing wrong at our end so please contact us for help.</p>
    </div>
  );
}

Component.propTypes = {
  // https://stackoverflow.com/a/47311600/392847
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderReference: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Component;
