import React from 'react';
import PropTypes from 'prop-types';
import bootstrapIcons from 'bootstrap-icons/bootstrap-icons.svg';

function Component(props) {
  const {
    label,
    icon,
    workingLabel,
    isWorking,
    isDisabled,
    onClick, // may be null, in which case this is a submit button for a Form
  } = props;

  return (
    <button
      className="btn btn-primary"
      type={onClick === null ? 'submit' : 'button'}
      disabled={isWorking || isDisabled}
      onClick={onClick}
    >
      { isWorking
        ? <ActiveBody label={`${workingLabel}...`} />
        : <InactiveBody label={label} icon={icon} />}
    </button>
  );
}

Component.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  workingLabel: PropTypes.string.isRequired,
  isWorking: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

Component.defaultProps = {
  icon: null,
  onClick: null,
  isDisabled: false,
};

export default Component;

function ActiveBody(props) {
  const { label } = props;
  return (
    <div className="d-flex align-items-center">
      <div className="text-nowrap">{label}</div>
      <div className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true" />
    </div>
  );
}

ActiveBody.propTypes = {
  label: PropTypes.string.isRequired,
};

function InactiveBody(props) {
  const { label, icon } = props;
  if (icon) {
    return (
      <div className="d-flex align-items-center">
        <svg className="bi me-2" width="1em" height="1em" fill="currentColor">
          <use xlinkHref={`${bootstrapIcons}#${icon}`} />
        </svg>
        <div className="text-nowrap">{label}</div>
      </div>
    );
  }

  // no icon
  return (
    <div className="text-nowrap">{label}</div>
  );
}

InactiveBody.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
};

InactiveBody.defaultProps = {
  icon: null,
};
