import React from 'react';
import logo from './logo.svg';

function Component() {
  return (
    <nav className="navbar navbar-light bg-light">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <a className="navbar-brand" href="https://www.silkhelix.co.uk/">
            <img src={logo} className="sh-logo" alt="Silk Helix" />
          </a>
          <strong>My HR Documents</strong>
        </div>
      </div>
    </nav>
  );
}

export default Component;
