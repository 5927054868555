import React from 'react';
import ExternalLink from './ExternalLink';

function Component() {
  return (
    <div className="row mt-4">
      <div className="col-md">
        <h2>Free Advice Call</h2>
        <p>
          If you would like to speak to a qualified consultant about using these documents,
          or even another people challenge you&apos;re facing, we&apos;ll be pleased to offer you a 30 minute call.
        </p>
        <p>
          <ExternalLink className="btn btn-primary text-white" href="https://www.silkhelix.co.uk/free-advice-call/">
            Book Now
          </ExternalLink>
        </p>
      </div>
      <div className="col-md">
        <h2>Useful Links</h2>
        <p>
          You may find the following articles from our <ExternalLink href="https://www.silkhelix.co.uk/knowledge/">Knowledge Hub</ExternalLink> useful:
        </p>
        <ul>
          <li><ExternalLink href="https://www.silkhelix.co.uk/knowledge/statutory-rates/">Statutory Rates</ExternalLink></li>
          <li><ExternalLink href="https://www.silkhelix.co.uk/knowledge/employee-probationary-periods/">Employee Probationary Periods</ExternalLink></li>
          <li><ExternalLink href="https://www.silkhelix.co.uk/knowledge/employment-contracts/">Employment Contracts</ExternalLink></li>
          <li><ExternalLink href="https://www.silkhelix.co.uk/knowledge/holiday-pay/">Holiday Pay</ExternalLink></li>
          <li><ExternalLink href="https://www.silkhelix.co.uk/knowledge/employment-status/">Guide to Employment Status</ExternalLink></li>
        </ul>
      </div>
    </div>
  );
}

export default Component;
