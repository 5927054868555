import React from 'react';
import ErrorAlert from './ErrorAlert';

function Component() {
  return (
    <div className="container">
      <ErrorAlert message="Unknown resource path." />
      <p>
        <span>If you believe this is a mistake and you expected to see something here then please give us a call on </span>
        <a href="tel:+441245910500">01245 910 500</a>
        <span> or email us at </span>
        <a href="mailto:support@silkhelix.co.uk">support@silkhelix.co.uk</a>.
      </p>
    </div>
  );
}

export default Component;
