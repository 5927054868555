import React from 'react';
import PropTypes from 'prop-types';
import bootstrapIcons from 'bootstrap-icons/bootstrap-icons.svg';

function Component(props) {
  const { message } = props;

  // https://getbootstrap.com/docs/5.0/components/alerts/#icons
  // https://icons.getbootstrap.com/#usage
  // https://create-react-app.dev/docs/adding-images-fonts-and-files
  return (
    <div className="alert alert-danger d-flex align-items-center my-2" role="alert">
      <svg className="bi flex-shrink-0 me-2" width="1.5em" height="1.5em" fill="currentColor">
        <use xlinkHref={`${bootstrapIcons}#exclamation-triangle-fill`} />
      </svg>
      <div>{message}</div>
    </div>
  );
}

Component.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Component;
