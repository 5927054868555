import React from 'react';

function Component() {
  return (
    <div className="text-center d-flex flex-column my-1 my-sm-0">
      <div className="small sh-tls">
        Copyright &copy; 2019-2021 Silk Helix Ltd. All rights reserved.
      </div>
      <div className="small sh-tls">
        Registered in England and Wales with company number 12080100.
      </div>
      <div className="small sh-tls">
        Registered office: Elizabeth House, 28 Baddow Road, Chelmsford, CM2 0DG.
      </div>
      <div className="small sh-tls mt-2">
        {process.env.NODE_ENV}
        {process.env.REACT_APP_GIT_SHA ? ` @ ${process.env.REACT_APP_GIT_SHA}` : ' [Git SHA and date will show here for production builds]'}
        {process.env.REACT_APP_DATE ? `, built: ${process.env.REACT_APP_DATE}` : null}
      </div>
    </div>
  );
}

export default Component;
